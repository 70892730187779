
window.addEventListener("load", () => {
  setTimeout(function() {
    const email = document.getElementById("user_email")
    const password = document.getElementById("user_password")
  
    email.value = "";
    password.value = "";

  }, 500)
})